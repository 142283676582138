import {HOME_PAGE} from 'src/constants/route'
import {useScreenSizeUp} from 'src/hooks/screenSize'
import {useCompanyName} from 'src/hooks/other'
import {LOGO_URLS} from 'src/constants/global'

export default function Logo(props: {
  isTop?: boolean
  white?: boolean
  bigLogo?: boolean
}) {
  const {isTop, white, bigLogo} = props

  const isUpMd = useScreenSizeUp('md')
  const companyName = useCompanyName()

  let showSmallSize = true //small by default

  if ((isUpMd && isTop) || bigLogo) {
    showSmallSize = false
  }

  return (
    <a href={HOME_PAGE} className="block" aria-label={`Logo ${companyName}`}>
      <img
        loading="eager"
        src={white ? LOGO_URLS.NORMAL_WHITE : LOGO_URLS.NORMAL}
        alt={companyName}
        width={263}
        height={22}
        className={`block cursor-pointer rounded-none ${
          showSmallSize ? 'hidden' : ''
        }`}
      />
      <img
        loading="eager"
        src={white ? LOGO_URLS.SMALL_WHITE : LOGO_URLS.SMALL}
        alt={companyName}
        width={39}
        height={21}
        className={`block cursor-pointer rounded-none ${
          showSmallSize ? '' : 'hidden'
        }`}
      />
    </a>
  )
}
