import {GetStaticProps} from 'next'
import Head from 'next/head'
import {useRouter} from 'next/router'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {useTranslation, withTranslation} from 'next-i18next'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppTypography from 'src/components/elements/typography/AppTypography'
import Logo from 'src/components/modules/globals/header/components/Logo'
import {DEFAULT_LOCALE, LOCALE_EN} from 'src/constants/locale'
import {useCompanyName} from 'src/hooks/other'

import Link from 'next/link'
import {useCurrentLocale} from 'src/hooks/locale'

function NotFoundPage() {
  const router = useRouter()
  const {t} = useTranslation(['404', 'common'])
  const companyName = useCompanyName()
  const currentLocale = useCurrentLocale()

  const handleGoHome = () =>
    router.push(currentLocale === LOCALE_EN ? '/en' : '/')
  const handleContactUs = () => {
    router.push(
      currentLocale === LOCALE_EN ? '/en/contact-us' : '/contacteznous',
    )
  }

  return (
    <>
      <Head>
        <title>Not Found - {companyName}</title>
      </Head>
      <div className="md:flex flex-row justify-start items-stretch max-h-screen">
        <div className="w-full md:w-1/2 pt-32 pb-24">
          <div className="w-full h-full flex flex-col justify-center items-center gap-12">
            <div onClick={handleGoHome}>
              <Logo bigLogo />
            </div>
            <div>
              <AppTypography
                className="text-center text-primary-main"
                variant="displayXLarge"
              >
                404
              </AppTypography>
              <AppTypography
                className="text-center py-6"
                neutralColor={800}
                variant="displayLarge"
              >
                {t('not_found')}
              </AppTypography>
              <AppTypography
                className="text-center pb-6"
                neutralColor={500}
                variant="body"
              >
                {t('not_found_description')}
              </AppTypography>
              <div className="flex flex-col justify-center items-stretch gap-4">
                <AppButton
                  color="primary"
                  fullWidth
                  variant="contained"
                  onClick={handleGoHome}
                >
                  {t('go_back_home')}
                </AppButton>
                <AppButton
                  fullWidth
                  variant="outlined"
                  onClick={handleContactUs}
                >
                  {t('contact_support')}
                </AppButton>
              </div>
            </div>
            <div className="flex flex-row justify-center items-stretch gap-4">
              <Link
                href={
                  currentLocale === LOCALE_EN
                    ? '/en/contact-us'
                    : '/contacteznous'
                }
              >
                {t('contact_us', {ns: 'common'})}
              </Link>
              <div className=" w-px bg-divider" />
              <a
                href="https://www.facebook.com/monsieurchalets"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
              <div className=" w-px bg-divider" />
              <a
                href="https://www.pinterest.ca/monsieurchalets/"
                target="_blank"
                rel="noreferrer"
              >
                Pinterest
              </a>
              <div className=" w-px bg-divider" />
              <a
                href="https://www.instagram.com/monsieurchalets/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
        <img
          alt="Not Found Background"
          className="hidden md:block rounded-none w-1/2 object-center object-cover"
          src="/images/contactUs.jpg"
        />
      </div>
    </>
  )
}

export const getStaticProps: GetStaticProps = async (context) => {
  const {locale} = context

  return {
    props: {
      ...(await serverSideTranslations(locale ?? DEFAULT_LOCALE, [
        '404',
        'common',
      ])),
    },
  }
}

const Translated404Page = withTranslation('translations')(NotFoundPage)

export default function Page404(props: any) {
  return <Translated404Page {...props} useSuspense={false} />
}
